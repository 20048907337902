import React, { useEffect, useState } from "react";
import { Row, Col, Card, Radio, Table, Typography, Button, Switch, Carousel } from "antd";
import { vehiclesApi } from "../../helpers/request.helper";

const { Title } = Typography;

function Vehicles() {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);

  const urlPrefix = "https://vehiclesapi.portalagramax.com.br/uploads/";
  const currentBusinessId = localStorage.getItem("agramax.businessId");

  useEffect(() => {
    if (currentBusinessId) {
      vehiclesApi.get(`/vehicles/bussiness/${currentBusinessId}`)
        .then((response) => {
          setVehicles(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error("Error fetching vehicles:", error);
          setLoading(false);
        });
    } else {
      console.error("No businessId found in localStorage");
      setLoading(false);
    }
  }, [currentBusinessId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleStatusChange = (checked, record) => {
    const newStatus = checked ? 'Portal' : 'Inativo';
    vehiclesApi.put(`/vehicles/${record.id}`, { status: newStatus })
      .then((response) => {
        setVehicles((prevVehicles) =>
          prevVehicles.map((vehicle) =>
            vehicle.id === record.id ? { ...vehicle, status: newStatus } : vehicle
          )
        );
      })
      .catch((error) => {
        console.error("Error updating vehicle status:", error);
      });
  };

  const columns = [
    {
      title: "Imagens",
      dataIndex: "uploads",
      key: "images",
      render: (uploads) => {
        const photoUploads = uploads.filter(upload => upload.type === 'photo');
        return (
          <Carousel autoplay dotPosition="left">
            {photoUploads.map((upload, index) => (
              <div key={index}>
                <img
                  src={urlPrefix + upload.url}
                  alt={`Upload ${index + 1}`}
                  style={{ maxWidth: '100%' }}
                />
              </div>
            ))}
          </Carousel>
        );
      },
    },
    {
      title: "Marca",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Modelo",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Versao",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Ano fabricação",
      dataIndex: "yearModel",
      key: "yearModel",
    },
    {
      title: "Placa",
      dataIndex: "plate",
      key: "plate",
    },
    {
      title: "Cridado em:",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      key: "action-switch",
      render: (text, record) => (
        <>
          <Switch
            checkedChildren="Portal Ativo"
            unCheckedChildren="Ativar portal"
            defaultChecked={record.status === "Portal"}
            onChange={(checked) => handleStatusChange(checked, record)}
          />
          {record.status === "Inactive" && <p>Item Inativo</p>}
        </>
      ),
    },
    {
      key: "action",
      render: (text, record) => (
        <Button href={`/details/${record.id}`} target="_blank">
          Detalhes/Editar
        </Button>
      ),
    },
    // Adicione mais colunas conforme necessário
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Veículos"
            extra={
              <Radio.Group defaultValue="a">
                <Radio.Button value="a">Aguardando</Radio.Button>
                <Radio.Button value="b">Portal</Radio.Button>
              </Radio.Group>
            }
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={vehicles}
                loading={loading}
                pagination={true}
                className="ant-border-space"
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Vehicles;
